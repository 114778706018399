import { lazy, Suspense, useEffect } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import Utils from "@leiteszeke/utils/web";
import AppLoader from "./components/AppLoader";

const queryClient = new QueryClient();

// Containers
const DefaultLayout = lazy(() => import("./layout/DefaultLayout"));
// Auth Pages
const Login = lazy(() => import("./pages/login/Login"));

const Logout = () => {
  const { onLogout } = Utils.useAuthActions();

  useEffect(() => {
    onLogout?.();
  }, [onLogout]);

  return null;
};

const App = () => {
  const { isLogged } = Utils.useAuth();

  if (isLogged === null) {
    return <AppLoader />;
  }

  if (!isLogged) {
    return (
      <QueryClientProvider client={queryClient}>
        <HashRouter>
          <Suspense fallback={<AppLoader />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <Suspense fallback={<AppLoader />}>
          <Routes>
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </QueryClientProvider>
  );
};

export default App;
