import { createStore } from "redux";
import { useSelector, TypedUseSelectorHook } from "react-redux";

type State = {
  sidebarShow: boolean;
  sidebarUnfoldable: boolean;
  asideShow: boolean;
  theme: string;
};

const initialState: State = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: "default",
};

type Args = { type?: string; [key: string]: any };

const changeState = (state = initialState, { type, ...rest }: Args) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;

export const useTypedSelector: TypedUseSelectorHook<State> = useSelector;
